import { render, staticRenderFns } from "./riskScore.vue?vue&type=template&id=7af6b6f1&scoped=true"
import script from "./riskScore.vue?vue&type=script&lang=js"
export * from "./riskScore.vue?vue&type=script&lang=js"
import style0 from "./riskScore.vue?vue&type=style&index=0&id=7af6b6f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af6b6f1",
  null
  
)

export default component.exports