<!--  -->
<template>
  <div class="score"> 
    <div class="title">评分标准</div>
 	<div class="min-titles">桂澜山资管公司备案产品投资者风险测评问卷（个人版）评分表</div>
		<table border="1">
			<tr>
				<td>题目</td>
				<td>除第12、13题之外其余题目</td>
				<td>第12题</td>
				<td>第13题</td>
			</tr>
			<tr>
				<td>分值</td>
				<td>A：2分</br>B：4分</br>C：6分</br>D：8分</br>E：10分</td>
				<td>A:0分</br>B-E任意一项：2分</br>B-E任意两项：4分</br>B-E任意三项：6分</br>B-E四项：8分</br></td>
				<td>A-C任意一项：2分</br>A-C任意两项：4分</br>A-C三项：6分</br>D:0分</td>
			</tr>
		</table>
		<p class="min-tit">投资者与桂澜山资管公司备案产品产品风险等级匹配表</p>
		<table border="1">
			<tr>
				<td>风险偏好</td>
				<td>分数统计</td>
				<td>适配的产品等级</td>
			</tr>
			<tr>
				<td>C1保守型</td>
				<td>[16,30]</td>
				<td>R1低风险</td>
			</tr>
			<tr>
				<td>C2稳健型</td>
				<td>[30,45]</td>
				<td>R2中低风险</td>
			</tr>
			<tr>
				<td>C3平衡型</td>
				<td>[45,60]</td>
				<td>R3中风险</td>
			</tr>
			<tr>
				<td>C4成长型</td>
				<td>[60,80]</td>
				<td>R4中高风险</td>
			</tr>
			<tr>
				<td>C5进取型</td>
				<td>[80,100]</td>
				<td>R5高风险</td>
			</tr>
		</table>
	<!-- <p>产品风险级别（由低到高）：R1-谨慎型 R2-稳健型 R3-平衡型 R4-进取型 R5-激进型</p> -->
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
    }
  },

  components: {},

  computed: {},

  methods: {}
}

</script>
<style lang='scss' scoped>
.min-titles{
  font-size: .9rem;
  color: #0062FF;
  padding-bottom: 1rem;
  line-height:1.2rem;
}
.min-tit{
  font-size: .8rem;
  color: #0062FF;
  padding-top: 1rem;
}
.score{padding: 2rem 1rem;color: #333;background: #fff;}
.score .title{font-size: 1.2rem;text-align: center;    margin-bottom: 2rem;}
.score .scores{color: #356BFF; color: #356BFF;height: 2rem;    display: flex;  justify-content: center;}
.score .scores li{float: left; font-size: .9rem; margin-right: .8rem;}
.score li{font-size: 0.8rem;}
.score .min-title{color: #356BFF;font-size: .9rem;}
.score p{margin: 1rem 0;font-size: 0.8rem;}
.score p b{color: #356BFF;}
.score table{ border:1px; border-collapse: collapse;border-spacing: 0;}
.score table tr{width: 100%;}
.score  table td{vertical-align: middle;width: 25%;font-size: .8rem;text-align: center;border: 0.01px solid #ddd;padding:0.5rem;line-height: 1.2rem;}
</style>


